<template>
  <div class="set-page">
    <div class="set-item">
      <div class="left">{{modify}}{{communityText.badge}}</div>
      <div class="right">
        <VanUploader
          class="logo-box"
          v-model="imageList"
          multiple
          accept="image/jpg, image/jpeg, image/png"
          image-fit="round"
          @oversize="onOversize"
          :max-size="100 * 1024"
          :max-count="1"
          :after-read = "handleModifyLolo"
        >
          <div class="logo-box"></div>
        </VanUploader>
        <van-icon name="arrow"></van-icon>
      </div>
    </div>
    <div class="set-item" @click="$gbUtils.handleToPage('/setCommunityName')">
      <div class="left">{{modify}}{{communityText.name}}</div>
      <van-icon name="arrow"></van-icon>
    </div>
    <div class="set-item" @click="$gbUtils.handleToPage('/setCommunityDec')">
      <div class="left">{{modify}}{{communityText.profile}}</div>
      <van-icon name="arrow"></van-icon>
    </div>
    <div class="set-item" @click="$gbUtils.handleToPage('/setCommunityBtn')">
      <div class="left">{{communityText.PersonalityButton}}</div>
      <van-icon name="arrow"></van-icon>
    </div>
  </div>
</template>

<script>
import { queryCommunityInfo, queryEditCommunity } from '@/services/community'
export default {
  name: '',
  data () {
    return {
      info: {},
      imageList: []
    }
  },
  computed: {
    modify () {
      return this.$t('community.modify')
    },
    communityText () {
      return this.$t('community.createInfo')
    },
    tips () {
      return this.$t('community.tips')
    }
  },
  methods: {
    onOversize (file) {
      this.$toast(this.tips.logoSize)
    },
    async getInfo () {
      const resp = await queryCommunityInfo()
      if (!resp.success) return
      this.info = resp.result
      if (this.info.logo) {
        const imgObj = {
          url: this.info.logo,
          isImage: true
        }
        this.imageList.push(imgObj)
      }
    },
    async handleModifyLolo (file) {
      console.log(file)
      const logo = file.content
      this.info.logo = logo
      const resp = await queryEditCommunity(this.info)
      if (!resp.success) return
      this.$toast(this.tips.logoModify)
    }
  },
  mounted () {
    this.getInfo()
  }
}
</script>

<style scoped lang="scss">
.set-item {
  background: rgba(255, 255, 255, 1);
  margin-top: 5px;
  padding: 15px 20px;
  box-sizing: border-box;
  max-height: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #C1C1C1;
    font-size: 18px;
  .left {
    color: #16172A;
    font-size: 15px;
  }
  .right {
    display: flex;
    align-items: center;
  }
  .logo-box {
    width: 60px;
    height: 60px;
    background: rgba(255, 255, 255, 0.39);
    border: 1px solid #707070;
    border-radius: 50%;
    margin-right: 15px;
  }
  /deep/.van-uploader__preview-image,
   .van-uploader__preview {
    width: 60px;
    height: 60px;
  }
  /deep/ .van-image__img {
     border-radius: 50%;
     object-fit: cover;
  }
}
</style>
